import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, getEnumOptions, LanguageDto, locales } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { omit } from 'lodash-es';

@Component({
  selector: 'app-language-item-form',
  templateUrl: './language-item-form.component.html',
  styleUrl: './language-item-form.component.scss',
})
export class LanguageItemFormComponent extends BaseForm<LanguageDto> implements OnInit {
  directionOptions = getEnumOptions(LanguageDto.DirectionEnum);
  localesMap = {};
  langCodeList = locales.map((x) => {
    this.localesMap[x.locale] = x.code.toLowerCase();
    return { name: x.name, langCode: x.locale, direction: x.direction };
  });
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'LANGUAGE');
  }
  ngOnInit(): void {}

  getData() {
    const langDataObj = this.formGroup?.getRawValue()?._languageSelector;
    const fData = omit(
      {
        ...this.formGroup.getRawValue(),
        name: langDataObj?.name,
        langCode: langDataObj?.langCode,
        direction: langDataObj?.direction,
      },
      '_languageSelector'
    );
    return this.viewModeService.viewMode == 'create'
      ? {
          ...this.getDataKeyValueFormat(fData),
          linkedDocuments: this.formGroup.controls.langFile.value ? [this.formGroup.controls.langFile.value] : [],
        }
      : this.getChangedFormValues(fData).updateItems;
  }

  setData(data: any) {
    this.patchFormData({
      ...data,
      _languageSelector: data ? { name: data?.name, langCode: data?.langCode, direction: data?.direction } : null,
    });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null),
      description: new FormControl(null),
      icon: new FormControl(null),
      langFile: new FormControl(null),
      langCode: new FormControl(null),
      direction: new FormControl(null),
      _languageSelector: new FormControl(null, Validators.required),
      // isDefault: new FormControl(true),
    });
  }
  get allowedFieldViewMode() {
    return this.data?.status && this.data?.status != 'DRAFT' ? 'view' : this.fieldViewMode;
  }
}
