<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <!-- <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="allowedFieldViewMode"
      ></app-basic-input>
    </div> -->
    <div class="col-12 md:col-12">
      <app-drop-down-input
        [label]="moduleFieldString + '.langCode.label' | translate: 'Language Code'"
        [placeholder]="moduleFieldString + '.langCode.placeholder' | translate: 'Enter Language Code'"
        [name]="'langCode'"
        [optionLabel]="'name'"
        [multi]="false"
        [items]="langCodeList"
        [optionValue]="undefined"
        [dataKey]="'langCode'"
        [control]="formGroup?.controls?._languageSelector"
        [badgeView]="false"
        [viewMode]="allowedFieldViewMode"
      >
        <ng-template let-item #selectedItemTemplate>
          <div class="flex flex-row gap-1 align-items-center">
            <span [class]="'currency-flag currency-flag-' + localesMap?.[item?.langCode]"></span>
            {{ item?.name }}
          </div>
        </ng-template>
        <ng-template let-item #itemTemplate>
          <div class="flex flex-row gap-1 align-items-center">
            <span [class]="'currency-flag currency-flag-' + localesMap?.[item?.langCode]"></span>
            {{ item?.name }}
          </div>
        </ng-template>
      </app-drop-down-input>
    </div>
    <!-- <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.langCode.label' | translate: 'Language Code'"
        [placeholder]="moduleFieldString + '.langCode.placeholder' | translate: 'Enter Language Code'"
        [name]="'langCode'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="langCodeList"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.langCode"
        [badgeView]="false"
        [viewMode]="allowedFieldViewMode"
      ></app-drop-down-input>
    </div> -->
    <!-- <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.direction.label' | translate: 'Direction'"
        [placeholder]="moduleFieldString + '.direction.placeholder' | translate: 'Enter Direction'"
        [name]="'direction'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="directionOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.direction"
        [badgeView]="true"
        [viewMode]="allowedFieldViewMode"
      ></app-drop-down-input>
    </div> -->
    <!-- <div class="col-12 md:col-6">
      <app-icon-selector
        [label]="moduleFieldString + '.icon.label' | translate: 'Icon'"
        [placeholder]="moduleFieldString + '.icon.placeholder' | translate: 'Select Icon'"
        [control]="formGroup?.controls?.icon"
        [viewMode]="allowedFieldViewMode"
      >
      </app-icon-selector>
    </div> -->
    <div class="col-12">
      <app-attachment-input
        [label]="moduleFieldString + '.langFile.label' | translate: 'Language Pack'"
        [placeholder]="moduleFieldString + '.langFile.placeholder' | translate: 'Enter Language Pack'"
        [control]="formGroup?.controls?.langFile"
        [viewMode]="allowedFieldViewMode"
        [dragAndDropMode]="true"
        [uploadLocation]="'CODE'"
        [useGlobalPasteEventListener]="false"
        [staticUploadWidth]="false"
        [accept]="'.csv'"
      ></app-attachment-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="allowedFieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
